import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Paciente } from 'app/models/paciente.interface';
import { GlobalVariable } from 'app/global/global';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { environment } from 'environments/environment.prod';
import { CanActivate, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class PacienteService {


  //
  titulo_login:string = 'MAIL';
  url:string = GlobalVariable.BASE_API_URL+"api/paciente/";
  

  //
  constructor(
    private http:HttpClient,
    private router:Router,
  ) {
    this.titulo_login = environment.PACIENTE_LOGIN;
  }


  //
  listar():Observable<any>{
    return this.http.get(this.url+"listarTodo");
  }


  //
  listarPacientesDeMedico(id:number):Observable<any>{
    return this.http.get(this.url+"medico-pacientes/"+id);
  }


  //
  mostrarformPaciente():Observable<any>{
    return this.http.get(this.url+"form-paciente");
  }


  //
  crear(nameImage:string,paciente:Paciente,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file){
      formData.append("image", file);
    }
    formData.append("nombreApellido",paciente.nombreApellido.trim());
    formData.append("tipoDocumento",paciente.tipoDocumento);
    formData.append("numeroDocumento",paciente.numeroDocumento.trim());
    formData.append("obraSocial",paciente.obraSocial);
    formData.append("numeroAfiliado",paciente.numeroAfiliado.trim());
    formData.append("direccion",paciente.direccion.trim());
    formData.append("email",paciente.email.trim());
    formData.append("telPais",paciente.telPais);
    formData.append("telCaract",paciente.telCaract.trim());
    formData.append("telNumero",paciente.telNumero.trim());
    formData.append("celPais",paciente.celPais);
    formData.append("celCaract",paciente.celCaract.trim());
    formData.append("celNumero",paciente.celNumero.trim());
    formData.append("codigoInterno",paciente.codigoInterno.trim());
    formData.append("nameImage",nameImage.trim());
    formData.append("modificar",paciente.modificar.toString());
    formData.append("password",paciente.password);
    return this.http.post(this.url+"crear",formData,{headers:headers});
  }


  //
  mostrarformEditarPaciente(id:number):Observable<any>{   
    return this.http.get(this.url+"form-editar-paciente/"+id);
  }


  //
  borrar(paciente:Paciente):Observable<any>{
    return this.http.delete(this.url+"borrar/"+paciente.id);
  }


  //
  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  //
  editar(nameImage:string,paciente:Paciente,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file) {
      formData.append("image",file);
    }
    formData.append("id",paciente.id.toString());
    formData.append("nombreApellido",paciente.nombreApellido.trim());
    formData.append("tipoDocumento",paciente.tipoDocumento);
    formData.append("numeroDocumento",paciente.numeroDocumento.trim());
    formData.append("obraSocial",paciente.obraSocial);
    formData.append("numeroAfiliado",paciente.numeroAfiliado.trim());
    formData.append("direccion",paciente.direccion.trim());
    formData.append("email",paciente.email.trim());
    formData.append("telPais",paciente.telPais);
    formData.append("telCaract",paciente.telCaract.trim());
    formData.append("telNumero",paciente.telNumero.trim());
    formData.append("celPais",paciente.celPais);
    formData.append("celCaract",paciente.celCaract.trim());
    formData.append("celNumero",paciente.celNumero.trim());
    formData.append("codigoInterno",paciente.codigoInterno.trim());
    formData.append("nameImage",nameImage.trim());
    formData.append("modificar",paciente.modificar.toString());

    return this.http.post(this.url+"editar",formData,{headers:headers});
  }


  //
  editarPerfil(nameImage:string,paciente:Paciente,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    console.log('paciente',paciente);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file) {
      formData.append("image", file);
    }
    formData.append("id",paciente.id.toString());
    formData.append("nombreApellido",paciente.nombreApellido.trim());
    formData.append("tipoDocumento",paciente.tipoDocumento);
    formData.append("numeroDocumento",paciente.numeroDocumento.trim());
    formData.append("obraSocial",paciente.obraSocial);
    formData.append("numeroAfiliado",paciente.numeroAfiliado.trim());
    formData.append("direccion",paciente.direccion.trim());
    formData.append("email",paciente.email.trim());
    formData.append("telPais",paciente.telPais);
    formData.append("telCaract",paciente.telCaract.trim());
    formData.append("telNumero",paciente.telNumero.trim());
    formData.append("celPais",paciente.celPais);
    formData.append("celCaract",paciente.celCaract.trim());
    formData.append("celNumero",paciente.celNumero.trim());
    formData.append("codigoInterno",paciente.codigoInterno.trim());
    formData.append("nameImage",nameImage.trim());
    formData.append("password",paciente.password);
    return this.http.post(this.url+"editar-perfil",formData,{headers:headers});
  }


  //
  login(Paciente:Paciente){
    let formData = new FormData(); 
    let key = encHex.parse("0123456789abcdef0123456789abcdef");
    let iv =  encHex.parse("abcdef9876543210abcdef9876543210");
    let encrypted = aes.encrypt(Paciente.password, key, {iv:iv, padding:padZeroPadding}).toString();
    if(this.titulo_login=='MAIL'){
      formData.append("email",Paciente.datoingreso);
    }
    else{
      formData.append("numeroDocumento",Paciente.datoingreso);
    }

    formData.append("password",encrypted);
    return this.http.post(this.url+"login",formData);
  }


  //
  cambiarClave(id:number){
    let formData = new FormData();
    formData.append("id",id.toString());
    return this.http.post(this.url+"cambiar-clave",formData);
  }


  //
  emailContacto(id:number,texto:string,filesUpload:File[],fileSaleSize:number){
    const formData = new FormData();
    const headers = new HttpHeaders();
    
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(filesUpload){
      formData.append('cantidadArchivos',filesUpload.length.toString());
      for (let i = 0; i < filesUpload.length; i++) {
        formData.append('archivos'+i,filesUpload[i]);
      }
    }
    formData.append("id",id.toString());
    formData.append("texto",texto);
    formData.append("tipoUsuario","paciente");
    return this.http.post(this.url+"contacto",formData,{headers: headers});
  }


  //
  cantidad(){
    return this.http.get(this.url+"cantidad");
  }


  // Levanta el token del localStorage
  getToken(){
    return localStorage.getItem('auth_token');
  }
  

  // Chequea que el token sea valido
  isValidToken(){
    return new Promise((resolve, reject) => {
      const token = this.getToken();
      if(token){
        const payload = this.payload(token);
        if(payload){
          resolve( this.url + 'login'  === payload.iss ? true : false)
        } 
        else {
          resolve( false)
        }
      }
    })
  }


  // Hace el parseo del jwt 
  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }


  // Chequea que el usuario este logueado
  isLoggedIn() {
    return this.isValidToken();
  }


  //setea el token en el local storage
  handleData(token){
    localStorage.setItem('auth_token', JSON.stringify(token));
  }
  

  //
  pacienteLogeado(): Observable<any> {
    return this.http.get(this.url + 'user-profile');
  }


  //
  datosAutorizacion(): Observable<any> {
    return this.http.get(this.url + 'datos-autorizacion');
  }


  //
  setDatosAutorizacion(data){
    localStorage.setItem('turno_habilitado', data.turnoHabilitado);
  }


  //
  deleteDatosAutorizacion(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
  }


  //
  logout(){
    localStorage.removeItem('turno_habilitado');
    localStorage.removeItem('auth_token');
    this.router.navigate(['/auth/login-paciente']);
  }


}
